import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { create } from 'ipfs-http-client';
import { Buffer } from 'buffer';
import jsonData from './setting.json';
import NFTCard from './NFTCard';
import { Web3 } from 'web3';
import CertiProof from '../abis/CertiProof.json';


const projectId = jsonData.INFURA_API_KEY; 
const projectSecret = jsonData.INFURA_API_KEY_SECRET; 
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const ipfs = create({ 
    host: 'ipfs.infura.io', 
    port: '5001', 
    protocol: 'https',
    headers: {
        authorization: auth,
    },
})

class Galery extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            network: 1,
            account : '', 
            sc : null,            
            data_oc : [] 
        }

        this.previewImage = this.previewImage.bind(this);
    }

    state = {
        isOpen: false
    };
    

    async componentDidMount(){
        await this.loadWeb3();
        await this.loadBlockchainData();
    }

    async loadWeb3(){
        if(window.ethereum){
            window.web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
        } else if(window.web3){
            window.web3 = new Web3(window.web3.currentProvider);
        } else {
            window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
    }

    async loadBlockchainData() {
        const web3 = window.web3;
        // Load account
        const accounts = await web3.eth.getAccounts();
        this.setState({ account: accounts[0] });
        const networkId = await web3.eth.net.getId();        
        this.setState({ network: parseInt(networkId) });        
        const networkData = CertiProof.networks[parseInt(networkId)];
        if(networkData){
            const sc = new web3.eth.Contract(CertiProof.abi, networkData.address);
            this.setState({ sc: sc });                    
            const recordsCount = await sc.methods.recordsCount().call();
            // console.log("recordsCount", recordsCount)
            // load Diploma Records
            for(let i = 1; i <= parseInt(recordsCount); ++i){
                const diplomaRecord = await sc.methods.diplomaRecords(i).call();
                const is_wl = await this.state.sc.methods.verifyUser(diplomaRecord.walletAddress).call();
                if(is_wl) {
                    //console.log("address wl", diplomaRecord.walletAddress)
                } else {
                    this.setState({
                        data_oc: [...this.state.data_oc, diplomaRecord],
                    })                    
                }
            }

        }else{
            window.alert('CertiProof Records contract not deployed to detected network!');
        }
    }

    dialog_title = "Dialog Title";
    dialog_content = "Dialog Content";

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    previewImage (event) {
        event.preventDefault();
        const image = document.querySelector('#image');
        const imgPreview = document.querySelector('.img-preview');

        imgPreview.style.display = 'block';

        const oFReader = new FileReader();
        oFReader.readAsDataURL( image.files[0] );
        
        oFReader.onload = function (oFREvent) {
            imgPreview.src = oFREvent.target.result;
        }
    }

    // mengambil nilai ketika form masukan diisi & mengembalikan hash sebagai Id Internal Ijazah secara real-time
    get_value() {
        const _NIM = document.getElementsByName('NIM')[0].value.toString;
        const _studentName = document.getElementsByName('studentName')[0].value;
        // const _studentAddress = document.getElementsByName('studentAddress')[0].value;
        const _major = document.getElementsByName('major')[0].value;
        const _department = document.getElementsByName('department')[0].value;
        const _faculty = document.getElementsByName('faculty')[0].value;
        const _graduationYear = document.getElementsByName('graduationYear')[0].value.toString;

        return document.getElementsByName('studentAddress')[0].value = btoa(_NIM+_studentName+_major+_department+_faculty+_graduationYear);
    }

    // minting to NFT
    handleMinting = async (uid) => {
        
        console.log(uid);   
        const mdata = [];
        this.state.data_oc.forEach((data, index) => {
            if (parseInt(data["id"]) === parseInt(uid)) {
                mdata.push(data)
            }
        });
        const data = mdata[0];
        const addrs = data["walletAddress"];
        console.log("addrs -> ", addrs);
        const metadata = {
          "name": data["studentName"],
          "description": "Data Ijazah "+data["studentName"]+" dengan NIM "+data["NIM"].toString(),
          "image": data["hashImage"],
          "attributes": [
                { "trait_type": "id", "value":  parseInt(uid)},
                { "trait_type": "nim", "value": parseInt(data["NIM"]) },
                { "trait_type": "student_name", "value": data["studentName"]  },
                { "trait_type": "record_hash", "value": data["internalDiplomaId"] },
                { "trait_type": "thesis_subject", "value": "" },
                { "trait_type": "major", "value": data["major"] },
                { "trait_type": "department", "value": data["department"] },
                { "trait_type": "faculty", "value": data["faculty"] },
                { "trait_type": "graduation_year", "value": parseInt(data["graduationYear"]) },
                { "trait_type": "image_hash", "value": data["hashImage"] },
                { "trait_type": "university", "value": data["university"] },
            ]
        };        
        const mtdata = JSON.stringify(metadata, (_, v) => ((typeof v === 'bigint')  || (typeof v === 'number')) ? v.toString() : v);
        // console.log(mtdata);

        this.dialog_title = "Silahkan tunggu";
        this.dialog_content = "Proses minting data ijazah sedang diproses, silahkan tunggu sampai selesai.";
        this.setState({ isOpen: true });

        try {
            const result = await ipfs.add(Buffer.from(mtdata));            
            console.info(result.path);
            const uri_ipfs = result.path;            
            if (uri_ipfs.length > 0) {
                // cek konten
                const is_wl = await this.state.sc.methods.verifyUser(addrs).call();
                console.log("is_wl -> ", is_wl)
                if(is_wl) {
                    const isContentOwn = await this.state.sc.methods.isContentOwned(uri_ipfs).call();
                    if (isContentOwn) {
                        this.dialog_title = "Error Duplikat Data";
                        this.dialog_content = "Sudah ada metadata yang sama dengan hash IPFS "+uri_ipfs;
                        this.setState({ isOpen: true });                        
                    } else {
                        this.state.sc.methods.safeMint(addrs, uri_ipfs).send({ from: this.state.account }).on('transactionHash', (hash) => {
                            this.dialog_title = "Sukses";
                            this.dialog_content = "Data Ijazah berhasil dijadikan NFT dengan hash metadata IPFS "+ uri_ipfs +" dan bukti transaksi di blockchain dengan alamat hash "+hash;
                            this.setState({ isOpen: true });   
                            this.setState({ loading: false });
                        });                        
                    }
                } else {
                    this.dialog_title = "Error";
                    this.dialog_content = "Alamat dompet tidak terdaftar di whitelist!, silahkan masukan dulu alamat dompet "+addrs+" ke daftar whitelist.";
                    this.setState({ isOpen: true });                    
                }
            }
        } catch (error) {
            console.log(error.message);
            this.dialog_title = "Error";
            this.dialog_content = "Proses Gagal. Error: "+error.message;
            this.setState({ isOpen: true });            
        }

    }

    handleWhitelist = async (e, addrs) => {
        const is_wl = await this.state.sc.methods.verifyUser(addrs).call();
        if(is_wl) {
            this.dialog_title = "Error";
            this.dialog_content = "Alamat dompet "+addrs+" sudah terdaftar di whitelist.";
            this.setState({ isOpen: true });
        } else {
            this.state.sc.methods.addUser(addrs).send({ from: this.state.account }).on('transactionHash', (hash) => {
                this.dialog_title = "Sukses";
                this.dialog_content = "Alamat wallet sudah ditambahkan ke daftar whitelist dengan bukti transaksi "+hash;
                this.setState({ isOpen: true });        
            });            
        }
    }

    render() {
        
        return (
            <>            
            <div className='container mx-auto'>
              <Modal
                show={this.state.isOpen}
                onHide={this.closeModal}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>{this.dialog_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.dialog_content}</Modal.Body>
              </Modal>

                <div className="shadow mb-2 fs-6 p-2 fw-bold bg-body mx-auto rounded bd-highlight border text-center border-secondary">DATA HASIL UPLOAD</div>

                <div className="row">
                {this.state.data_oc.length === 0 && 
                <div className="shadow mb-2 fs-6 p-2 bg-body mx-auto rounded bd-highlight border text-center border-secondary">                
                Belum ada data on-chain yang perlu di-whitelist
                </div>
                }
                {this.state.data_oc.map(token => 
                    <NFTCard 
                        handleWhitelist={this.handleWhitelist} 
                        handleMinting={this.handleMinting} 
                        key={token.NIM} 
                        nft={token} 
                    />)
                }
                </div>
            </div>            
            </>
        );
    }
}


export default Galery;
