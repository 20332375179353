import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

class About extends Component {
    render() {
        return (
            <>
                <Container className='my-4'>
                    <Col sm={12} md={8} lg={6} className='mx-auto'>
                        <h1 className='h3 mb-4'>DApp Simulasi Sistem Pencatatan Ijazah</h1>

                        <p className='justify-text'>Aplikasi terdesentralisasi (DApp) ini dibangun menggunakan kombinasi teknologi <i>Blockchain</i> dengan <i>InterPlanetary File System</i> (IPFS), yang dirancang untuk mensimulasikan penerbitan ijazah universitas. Sekaligus bertujuan untuk memperoleh informasi terkait performansi atau kualitas jaringan sistem dengan melalui teknik pengujian <i>Quality of Service</i> (QoS), serta mengetahui berapa kisaran biaya yang diperlukan untuk pengunggahan setiap ijazahnya.</p>

                        <p className="justify-text">Pada halaman About ini telah tersedia panduan konfigurasi dompet MetaMask, sekaligus beserta implementasi simulasi penerbitan ijazahnya, baik melalui penggunaan perangkat desktop maupun mobile app yang dapat dilihat pada bagian bawah berikut ini.</p>
                    </Col>

                    <section className="config-n-simulation">
                        <Tab.Container id="config-n-simulation-tabs" defaultActiveKey="first">
                            <Nav variant="pills" className="nav-pills my-4 justify-content-center align-items-center" id="pills-tab">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Desktop</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Mobile</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <Col sm={12} md={8} lg={6} className='mx-auto'>
                                        <h2 className='h4 mb-4'>A. MetaMask Extension - Desktop Browser</h2>

                                        <p className='justify-text'>1.) Download ekstensi <a href="https://metamask.io/" rel="noreferrer" target='_blank'>MetaMask</a> untuk web browser, jika Anda belum pernah mendownload sebelumnya, serta gunakan web browser yang mendukung web 3.0 atau yang direkomendasikan oleh MetaMask sendiri. Pada demo konfigurasi kali ini akan menggunakan ekstensi MetaMask yang versi 10.30.1.</p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/desktop/step-1.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded' width='100%' />
                                        </div>

                                        <p className='justify-text'>2.) Setelah ekstensi MetaMask berhasil terinstal maka akan otomatis tampil menu pilihan <i>'Create a new wallet'</i> bagi yang belum memiliki akun dompet MetaMask, lalu adapun pilihan <i>'Import an existing wallet'</i> bagi yang telah memiliki akun dompet MetaMask.</p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/desktop/step-2.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded' width='100%' />
                                        </div>

                                        <p className='justify-text'>3.) Jika ingin membuat akun dompet MetaMask baru, Anda perlu mengisi password untuk dompet MetaMask.</p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/desktop/step-3.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded' width='100%' />
                                        </div>

                                        <p className='justify-text'>4.) Pada langkah berikutnya akan tampil menu pilihan keamanan. Jika memilih <i>'Secure my wallet'</i> nanti akan memunculkan 12-24 kata acak <i>(Seed Phrase)</i>, sebagai frasa pemulihan rahasia yang berfungsi untuk recovery akun dompet MetaMask. Serta pastikan seed phrase tersebut jangan sampai dilihat dan diketahui orang lain.</p>
                                        <p className='justify-text'>Pada demo konfigurasi kali ini akan memilih pilihan <i>'Remind me later'</i>, karena frasa pemulihan rahasia MetaMask masih akan dapat dilihat pada menu pengaturan MetaMask nantinya.</p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/desktop/step-4.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded' width='100%' />
                                        </div>

                                        <p className='justify-text'>5.) Setelah berhasil membuat atau mengimpor akun dompet MetaMask akan muncul tampilan kurang lebih seperti berikut, dengan default jaringannya ialah Ethereum Mainnet (EIP-1559).</p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/desktop/step-5.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded' width='100%' />
                                        </div>

                                        <p className='justify-text'>6.) Klik nama jaringan Blockchain yang aktif kemudian pilih <i>'Add Network'</i> untuk menambahkan jaringan Blockchain lainnya.</p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/desktop/step-6.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded' width='100%' />
                                        </div>

                                        <p className='justify-text'>7.) MetaMask akan mengarahkan ke halaman Settings &gt; Networks &gt; Add a network. Secara default MetaMask telah menyediakan jaringan populer lainnya yang kompatibel dengan Ethereum Blockchain. Setelah itu cari jaringan Polygon Mainnet, kemudian klik <i>'Add'</i> serta <i>'Approve'</i>.</p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/desktop/step-7.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded' width='100%' />
                                        </div>
                                        
                                        <p className='justify-text'>8.) Jika berhasil jaringan ditambahkan akan muncul pop-up <i>'Network added successfully!'</i>, kemudian pilih <i>'Switch to Polygon Mainnet'</i></p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/desktop/step-8.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded' width='100%' />
                                        </div>

                                        <p className='justify-text'>9.) Setelah berhasil terkoneksi dengan jaringan Polygon Mainnet. Anda dapat kembali lagi ke halaman utama <a href="https://skripsi.fawwazkautsar.com">DApp Simulasi Sistem Pencatatan Ijazah</a>, yang dapat diakses pada url subdomain skripsi.fawwazkautsar.com.</p>
                                        <p className='justify-text'>Refresh halaman DApp, lalu MetaMask akan memunculkan pop-up untuk menghubungkan DApp dengan jaringan yang aktif pada MetaMask. Dengan cara melalui memilih akun atau wallet address yang akan digunakan, lalu klik <i>'Next'</i> serta <i>'Connect'</i>.</p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/desktop/step-9.jpg" alt="MetaMask Configuration Steps to Connect DApp" className='rounded' width='100%' />
                                        </div>

                                        <p className='justify-text'>10.) Ketika telah berhasil muncul tampilan halaman utama yang kurang lebih seperi berikut, maka DApp telah dapat digunakan sepenuhnya. Namun jika terdapat notifikasi bahwa contract tidak ter-deploy, maka perlu dicek kembali jaringan yang aktif di MetaMask atau terdapat konfigurasi jaringan yang salah dan tidak sesuai dengan yang terkini (deprecated).</p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/desktop/step-10.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded' width='100%' />
                                        </div>
                                    </Col>
                                </Tab.Pane>

                                <Tab.Pane eventKey="second">
                                    <Col sm={12} md={8} lg={6} className='mx-auto'>
                                        <h2 className='h4 mb-4'>A. MetaMask - Mobile Application</h2>

                                        <p className='justify-text'>1.) Download aplikasi <a href="https://metamask.io/" rel="noreferrer" target='_blank'>MetaMask</a> untuk mobile phone, jika Anda belum pernah mendownload sebelumnya. Pada demo konfigurasi kali ini akan menggunakan aplikasi MetaMask yang versi 6.5.0.</p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/mobile/step-1.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded mx-auto d-block' width='40%' />
                                        </div>

                                        <p className='justify-text'>2.) Setelah aplikasi MetaMask berhasil terinstal, buka aplikasi MetaMask tersebut. Kemudian akan tampil halaman awal seperti berikut, silahkan klik tombol <i>'Get Started'.</i></p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/mobile/step-2.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded mx-auto d-block' width='30%' />
                                        </div>

                                        <p className='justify-text'>3.) Setelah itu akan otomatis tampil menu pilihan <i>'Create a new wallet'</i> bagi yang belum memiliki akun dompet MetaMask, lalu adapun pilihan <i>'Import Using Secret Recovery Phrase'</i> bagi yang telah memiliki akun dompet MetaMask.</p>
                                        <p className='justify-text'>Jika memilih buat akun dompet MetaMask baru, maka akan muncul <i>Policy Agreement</i> yang harus disetujui pengguna. Setelah itu Anda perlu mengisi password untuk dompet MetaMask. Pada tahap ini penulis tidak bisa mendokumentasikan, karena aplikasi tidak mengizinkan untuk mengambil screenshot.</p>

                                        <p className='justify-text'>4.) Pada langkah berikutnya akan tampil menu pilihan keamanan. Jika memilih <i>'Start'</i> nanti akan memunculkan 12-24 kata acak <i>(Seed Phrase)</i>, sebagai frasa pemulihan rahasia yang berfungsi untuk recovery akun dompet MetaMask.</p>
                                        <p className='justify-text'>Tidak perlu khawatir jika ingin memilih pilihan <i>'Remind me later'</i>, karena frasa pemulihan rahasia MetaMask masih akan dapat dilihat pada menu pengaturan MetaMask nantinya. Pada demo konfigurasi kali ini akan memilih pilihan <i>'Start'.</i></p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/mobile/step-3.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded mx-auto d-block' width='30%' />
                                        </div>

                                        <p className='justify-text'>5.) Sebelum klik <i>Start</i> nanti akan muncul 12-24 kata acak atau seed phrase, pastikan seed phrase tersebut jangan sampai dilihat dan diketahui orang lain.</p>

                                        <p className='justify-text'>6.) Isi konfirmasi password yang telah dibuat sebelumnya. Setelah itu catat dan simpan seed phrase pada tempat yang aman, lalu pilih <i>'Continue'</i>.</p>

                                        <p className='justify-text'>7.) Isi konfirmasi frasa pemulihan rahasia dengan menggunakan seed phrase sebelumnya, jika telah sesuai lalu pilih <i>'Complete Backup'</i>.</p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/mobile/step-7.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded mx-auto d-block' width='30%' />
                                        </div>

                                        <p className='justify-text'>8.) Jika berhasil memasukan seed phrase, maka akan muncul tampilan halaman utama yang kurang lebih seperi berikut.</p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/mobile/step-8.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded mx-auto d-block' width='30%' />
                                        </div>

                                        <p className='justify-text'>9.) Klik nama jaringan Blockchain yang aktif kemudian pilih <i>'Add Network'</i> untuk menambahkan jaringan Blockchain lainnya.</p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/mobile/step-9.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded mx-auto d-block' width='30%' />
                                        </div>

                                        <p className='justify-text'>10.) MetaMask akan mengarahkan ke halaman Settings &gt; Networks &gt; Add Network. Secara default MetaMask telah menyediakan jaringan populer lainnya yang kompatibel dengan Ethereum Blockchain. Kemudian cari jaringan Polygon Mainnet dan klik <i>'Add'</i>.</p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/mobile/step-10.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded mx-auto d-block' width='30%' />
                                        </div>

                                        <p className='justify-text'>11.) Setelah itu klik <i>'Approve'</i> serta <i>'Switch to network'</i>, maka jaringan Polygon telah berhasil ditambahkan dan otomatis sekaligus digunakan.</p>
                                        <div class="border border-2 mb-4 rounded">
                                            <img src="/MetaMask-Config/mobile/step-11.png" alt="MetaMask Configuration Steps to Connect DApp" className='rounded mx-auto d-block' width='30%' />
                                        </div>
                                    </Col>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </section>
                </Container>
            </>
        );
    }
}

export default About;
