import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// import Identicon from 'identicon.js';
import Modal from 'react-bootstrap/Modal';
import { create } from 'ipfs-http-client';
import { Buffer } from 'buffer';
import jsonData from './setting.json';
import './App.css';
import axios from "axios";
import { Web3 } from 'web3';
import CertiProof from '../abis/CertiProof.json';

/*
Sementara dilakukan pengujian pengecekan gambar yang duplikat dengan API dari unmarshal.io dengan mencari hash image yang tersimpan di ipfs
nanti smartcontract akan diupdate lagi dengan membuat index 
- index hash gambar ipfs yang sudah diupload di fungsi publishData
- index nim dan hash_ipfs di fungsi safeMint
- index hash_ipfs dengan id nft
Pembuatan memory index dilakukan agar tidak ketergantungan dengan API pihak ketiga
*/

const projectId = jsonData.INFURA_API_KEY;
const projectSecret = jsonData.INFURA_API_KEY_SECRET;
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const ipfs = create({ 
    host: 'ipfs.infura.io', 
    port: '5001', 
    protocol: 'https',
    headers: {
        authorization: auth,
    },
})

class Upload extends Component {
    constructor(props) {
        super(props);
        this.previewImage = this.previewImage.bind(this);
        this.state = { 
            isOpen : false,
            network: 1,
            account : '', 
            sc_account : '', 
            sc : null,            
            hash_img_data : [],
            data_nim: []
        }

        // this.checkDuplicateImage("qmxumlf7mkqtxqwqfgtpjcb7buqs9w21aaqcxghyhjcihc");
    }

    async componentDidMount() {
        await this.loadWeb3();
        await this.loadBlockchainData();
    }

    async loadWeb3(){
        if(window.ethereum){
            window.web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
        } else if(window.web3){
            window.web3 = new Web3(window.web3.currentProvider);
        } else {
            window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
    }

    async loadBlockchainData() {
        const web3 = window.web3;
        const accounts = await web3.eth.getAccounts();
        this.setState({ account: accounts[0] });

        // Network ID
        const networkId = await web3.eth.net.getId();
        const networkData = CertiProof.networks[parseInt(networkId)];
        //console.log(networkData)
        this.setState({ sc_account: networkData.address});

        if(networkData){
            const sc = new web3.eth.Contract(CertiProof.abi, networkData.address);
            this.setState({ sc: sc});

            const recordsCount = await sc.methods.recordsCount().call();

            // load Diploma Records
            for(let i = 1; i <= recordsCount; ++i){
                const hash_data = await sc.methods.diplomaRecords(i).call();
                //console.log("nim", parseInt(hash_data.NIM))
                //console.log("image hash", hash_data.hashImage)
                this.setState({
                    hash_img_data: [...this.state.hash_img_data, hash_data.hashImage],
                    data_nim: [...this.state.data_nim, parseInt(hash_data.NIM).toString()],
                })
            }

            this.setState({ 
                loading: false 
            });
        }else{
            window.alert('University Diploma Records contract not deployed to detected network!');
        }
    }


    state = {
        isOpen: false
    };
    
    dialog_title = "Dialog Title";
    dialog_content = "Dialog Content";
    min_char = 3;

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    previewImage (event) {
        event.preventDefault();
        const image = document.querySelector('#image');
        const imgPreview = document.querySelector('.img-preview');

        imgPreview.style.display = 'block';

        const oFReader = new FileReader();
        oFReader.readAsDataURL( image.files[0] );
        
        oFReader.onload = function (oFREvent) {
            imgPreview.src = oFREvent.target.result;
        }
    }

    captureFile = event => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new window.FileReader();
        reader.readAsArrayBuffer(file);

        reader.onloadend = () => {
            this.setState({ buffer: Buffer(reader.result) });
            console.log('buffer : ', this.state.buffer);
        }
    }

    checkDuplicateImage = async (ipfs_img) => {
        const url = jsonData.UNMARSHAL_URL_PARSER;
        const postData = {
          query: "SELECT * FROM certiproof_idrwu.diploma_record_created_events where event_hash_image='"+ipfs_img+"' LIMIT 1"
        };        
        const config = {
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }        
        try {
          const response = await axios.post(url, JSON.stringify(postData), config);
          // console.log("response", response);
          if(response.data.row_count > 0) {
            // console.log("response data", response.data.row_count);
            // console.log("response data", response.data.data.rows[0]);
            return true;
          } else {
            return false
          }
        } catch (error) {
          console.log(error);
        }
    };

    removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    uploadImage = async (_NIM, _walletAddress, _studentName, _studentAddress, _major, _department, _faculty, _graduationYear, _university) => {
        console.log('Submitting file to ipfs');
        // Adding file to the IPFS
        const web3 = window.web3;        
        const _gy = new Date().getFullYear();
        try {
            this.dialog_title = "Silahkan Tunggu";
            this.dialog_content = "Kami sedang memproses gambar untuk diupload ke IPFS...";
            this.setState({ isOpen: true });               
            const result = await ipfs.add(this.state.buffer);
            // console.log(result);
            const addrs = _walletAddress.replace(/\s{2,}/g,'').trim();
            if (this.removeExtraSpace(_studentName).length >= this.min_char && 
                this.removeExtraSpace(_major).length >= this.min_char && 
                this.removeExtraSpace(_department).length >= this.min_char && 
                this.removeExtraSpace(_faculty).length >= this.min_char && 
                this.removeExtraSpace(_university).length >= this.min_char) {
                if (_graduationYear <= _gy) {
                    if (result.path.length > 0) {
                        if(web3.utils.isAddress(addrs) && (!this.state.data_nim.includes(_NIM))) {
                            // console.log("alamat dompet ", addrs);
                            //console.log('ipfs image hash ', result.path);
                            this.setState({ loading: true });
                            const balance = await this.state.sc.methods.balanceOf(addrs).call();
                            //console.log("balance -> ", balance)

                            // if (this.checkDuplicateImage(result.path)) {
                            if (this.state.hash_img_data.includes(result.path)) {
                                this.dialog_title = "Error";
                                this.dialog_content = "Gambar yang diupload sudah digunakan dan tersimpan di IPFS dengan hash "+result.path+", silahkan gunakan gambar yang lain.";
                                this.setState({ isOpen: true });                                                                       
                            } else {
                                if(balance <= 0) {
                                    this.state.sc.methods.publishData( _NIM, addrs, _studentName, _studentAddress, _major, _department, _faculty, _graduationYear, _university, result.path).send({ from: this.props.wallet_admin }).on('transactionHash', (hash) => {
                                        this.setState({ loading: false });
                                        this.dialog_title = "Sukses";
                                        this.dialog_content = "Upload gambar ke IPFS berhasil dengan hash "+result.path+" dan data berhasil disimpan di blockchain dengan bukti transaksi "+hash;
                                        this.setState({ isOpen: true });                                       
                                    });                        
                                } else {
                                    this.dialog_title = "Error";
                                    this.dialog_content = "Alamat dompet "+addrs+" sudah pernah digunakan. Silahkan ganti dengan alamat dompet yang lain.";
                                    this.setState({ isOpen: true });                                       
                                }                                
                            }
                        } else {
                            this.dialog_title = "Error";
                            this.dialog_content = "Cek format alamat dompet anda atau NIM `"+_NIM.toString()+"` sudah digunakan.";
                            this.setState({ isOpen: true });                                                                                   
                        }          
                    }
                } else {
                    this.dialog_title = "Error";
                    this.dialog_content = "Tahun kelulusan harus lebih kecil atau sama dengan tahun ini ("+_gy.toString()+").";
                    this.setState({ isOpen: true });                                                       
                }
            } else {
                this.dialog_title = "Error";
                this.dialog_content = "Minimal "+this.min_char.toString()+" karakter input.";
                this.setState({ isOpen: true });                                                                       
            }
        } catch (error) {
            console.log(error.message);
            this.dialog_title = "Error";
            this.dialog_content = "Pesan kesalahan: "+error.message;
            this.setState({ isOpen: true });                                                       
        }

    }


    default_value_university(){
        return document.getElementsByName('university')[0].value = "UNIVERSITAS PENDIDIKAN INDONESIA";
    }

    // mengambil nilai ketika form masukan diisi & mengembalikan hash sebagai Id Internal Ijazah secara real-time    
    get_value() {
        const _NIM = document.getElementsByName('NIM')[0].value.toString;
        const _studentName = document.getElementsByName('studentName')[0].value;
        const _walletAddress = document.getElementsByName('walletAddress')[0].value.toString();
        // const _studentAddress = document.getElementsByName('studentAddress')[0].value;
        const _major = document.getElementsByName('major')[0].value;
        const _department = document.getElementsByName('department')[0].value;
        const _faculty = document.getElementsByName('faculty')[0].value;
        const _university = document.getElementsByName('university')[0].value;
        const _graduationYear = document.getElementsByName('graduationYear')[0].value.toString();

        return document.getElementsByName('studentAddress')[0].value = btoa(_NIM+_studentName+_major+_department+_faculty+_graduationYear+_walletAddress+_university);
    }

    render() {
        return (
            <>                                        
                <Container className='my-4'>
                  <Modal
                    show={this.state.isOpen}
                    onHide={this.closeModal}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{this.dialog_title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.dialog_content}</Modal.Body>
                  </Modal>
                    <div className="shadow mb-2 fs-6 p-2 fw-bold bg-body mx-auto rounded bd-highlight border text-center border-secondary">UPLOAD DATA IJAZAH</div>                  
                    <Form onSubmit={(event) => {
                        event.preventDefault();
                        const _NIM = this.NIM.value;
                        const _walletAddress = this.walletAddress.value;
                        const _studentName = this.studentName.value;                        
                        // const _studentAddress = this.studentAddress.value;
                        const _major = this.major.value;
                        const _department = this.department.value;
                        const _faculty = this.faculty.value;
                        const _university = this.university.value;                        
                        const _graduationYear = this.graduationYear.value;
                        const _studentAddress = btoa(_NIM.toString()+_studentName+_major+_department+_faculty+_graduationYear.toString());

                        this.uploadImage(_NIM, _walletAddress, _studentName, _studentAddress, _major, _department, _faculty, _graduationYear, _university);
                    }}>
                        <Form.Group>
                            <Row className="justify-content-md-center">
                                <Col xs={6} md={4}>
                                    <Form.Label>NIM</Form.Label>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Control 
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}                                    
                                    type="number" placeholder="Masukan NIM" min="1" max="1000000000" name="NIM" 
                                    ref={(input1) => { this.NIM = input1 }} onChange={this.get_value} required autoFocus />
                                </Col>
                            </Row>                        
                        </Form.Group>
                        <Form.Group>
                            <Row className="justify-content-md-center">
                                <Col xs={6} md={4}>
                                    <Form.Label>Alamat Dompet Mahasiswa</Form.Label>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Control type="text" placeholder="Masukan alamat dompet" name="walletAddress" ref={(input2) => { this.walletAddress = input2 }} onChange={this.get_value} required />
                                </Col>                                
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row className="justify-content-md-center">
                                <Col xs={6} md={4}>
                                    <Form.Label>Nama Mahasiswa</Form.Label>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Control type="text" placeholder="Masukan nama mahasiswa" name="studentName" ref={(input3) => { this.studentName = input3 }} onChange={this.get_value} required />
                                </Col>                                
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row className="justify-content-md-center">
                                <Col xs={6} md={4}>
                                    <Form.Label>Id Ijazah Internal</Form.Label>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Control type="text" placeholder="Id Ijazah Internal" pattern=".{15,}" title="15 characters minimum" name="studentAddress" ref={(input4) => { this.studentAddress = input4 }} required disabled />
                                </Col>                                
                            </Row>

                        </Form.Group>

                        <Form.Group>
                            <Row className="justify-content-md-center">
                                <Col xs={6} md={4}>
                                    <Form.Label>Program Studi</Form.Label>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Control type="text" placeholder="Masukan program studi" name="major" ref={(input5) => { this.major = input5 }} onChange={this.get_value} required />
                                </Col>                                
                            </Row>

                        </Form.Group>
                        <Form.Group>
                            <Row className="justify-content-md-center">                        
                                <Col xs={6} md={4}>
                                    <Form.Label>Departemen</Form.Label>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Control type="text" placeholder="Masukan departemen" name="department" ref={(input6) => { this.department = input6 }} onChange={this.get_value} required />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row className="justify-content-md-center">
                                <Col xs={6} md={4}>
                                    <Form.Label>Fakultas</Form.Label>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Control type="text" placeholder="Masukan fakultas" name="faculty" ref={(input7) => { this.faculty = input7 }} onChange={this.get_value} required />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group>
                            <Row className="justify-content-md-center">
                                <Col xs={6} md={4}>
                                    <Form.Label>Tahun Lulus</Form.Label>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Control type="number" placeholder="Masukan tahun lulus" min="1950" name="graduationYear" ref={(input8) => { this.graduationYear = input8 }} onChange={this.get_value} required />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Row className="justify-content-md-center">
                                <Col xs={6} md={4}>
                                    <Form.Label>Universitas</Form.Label>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Control type="text" placeholder="Universitas" name="university" value="UNIVERSITAS PENDIDIKAN INDONESIA"  ref={(input9) => { this.university = input9 }} onChange={this.default_value_university} required readOnly />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group>
                            <Row className="justify-content-md-center">
                                <Col xs={6} md={4}>
                                    <Form.Label>Upload Ijazah</Form.Label>
                                </Col>
                                <Col xs={12} md={6}>
                                    <img src="" alt="" className="img-preview img-fluid mb-3 col-sm-5" />
                                    <Form.Control type="file" placeholder="Masukan file (img) ijazah" id="image" accept='.jpg, .jpeg, .png, .bmp, .gif' onChange={(e) => { this.captureFile(e); this.previewImage(e); }} required />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Row className="justify-content-md-center p-2">
                            <Col md="auto">
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Col>                            
                        </Row>

                    </Form>

                    <hr />
                    
                </Container>
            </>
        );
    }
}

export default Upload;
