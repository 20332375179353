import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import jsonData from './setting.json';
import GalleryCard from './GalleryCard';
import SearchResult from './SearchResult';
import { Web3 } from 'web3';
import CertiProof from '../abis/CertiProof.json';
import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import InputGroup from 'react-bootstrap/InputGroup';
import axios from "axios";


class GalleryNFT extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isOpen : false,
            network: 1,
            account : '', 
            sc_account : '', 
            sc : null,            
            data_nft : [],
            metadata : [],
            search_result : []
        }

        this.previewImage = this.previewImage.bind(this);
    }

    /**
    state = {
        isOpen: false
    };
    **/
    
    dialog_title = "Dialog Title";
    dialog_content = "Dialog Content";

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    async componentDidMount(){
        await this.loadWeb3();
        await this.loadBlockchainData();
        try {
          await Moralis.start({
            apiKey: jsonData.MORALIS_API,
          });

        } catch(error) {
            console.log(error.message)
        }
        await this.getAllNFT();
    }

    async loadWeb3(){
        if(window.ethereum){
            window.web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
        } else if(window.web3){
            window.web3 = new Web3(window.web3.currentProvider);
        } else {
            window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
    }

    async loadBlockchainData() {
        const web3 = window.web3;
        // Load account
        const accounts = await web3.eth.getAccounts();
        this.setState({ account: accounts[0] });        
        const networkId = await web3.eth.net.getId();        
        this.setState({ network: parseInt(networkId) });        
        const networkData = CertiProof.networks[parseInt(networkId)];
        if(networkData){
            const sc = new web3.eth.Contract(CertiProof.abi, networkData.address);
            this.setState({ sc_account: sc["_address"] });                    
            this.setState({ sc: sc });                    
            // const recordsCount = await sc.methods.recordsCount().call();
        }else{
            window.alert('CertiProof Records contract not deployed to detected network!');
        }
    }

    /*
    https://api.unmarshal.com/v3/matic-testnet/address/0x13ca59173336dbff59a60f34ae6951e0c24446b9/nft-assets?contract=0x4461A5DFaB73e8177238E653F2f36f61FC8287c6&auth_key=QuXzOGnHlVXU5o5cP1LkUuC6gJCviH83obfXZXj0
    jsonData.UNMARSHAL_AUTH_KEY
    response.nft_assets[0].token_id
    */

    searchNIM = async (event) => {
        // console.log("metadata before", this.state.metadata);
        const url = jsonData.UNMARSHAL_URL_PARSER;
        const nim = event.target.value.replace(/\s{2,}/g,'').trim();
        console.log("nim ...", nim);
        if (nim.length > 0 && nim !== "") {
            this.dialog_title = "Silahkan tunggu";
            this.dialog_content = "Kami sedang mencari data di blockchain untuk kata kunci `"+nim.toString()+"` ...";
            this.setState({ isOpen: true });        
            const postData = {
              query: "SELECT method_to FROM certiproof_idrwu.safe_mint_methods where method_to in (SELECT method_wallet_address FROM certiproof_idrwu.publish_data_methods where method_nim="+nim.toString()+")"
            };        
            const config = {
              headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
              }
            }        
            // const mdata = [];
            this.setState({ search_result: [] });                                
            try {
              const response = await axios.post(url, JSON.stringify(postData), config);
              //console.log("response", response);
              //console.log("response.data.row_count ", response.data.row_count)
              if(response.data.row_count > 0) {
                // console.log("response data", response.data.row_count);
                //console.log("response data", response.data.data.rows.length);
                if (response.data.data.rows.length > 0) {
                    response.data.data.rows.forEach(async(data, index) => {
                        //console.log("data",data[0]);
                        const walletaddr = data[0];
                        // const walletaddr = response.data.data.rows[0][0];
                        const url_get = "https://api.unmarshal.com/v3/matic-testnet/address/"+walletaddr+"/nft-assets?contract="+this.state.sc_account+"&auth_key="+jsonData.UNMARSHAL_AUTH_KEY;
                        const response_get = await axios.get(url_get, config); 
                        //console.log(response_get.data.items_on_page);          
                        if(response_get.data.items_on_page > 0) {
                            const ipfs_hash = response_get.data.nft_assets[0].external_link;
                            const resp_ipfs = await axios.get(ipfs_hash, config);
                            //console.log("resp_ipfs", resp_ipfs.data.image);
                            this.state.search_result.push(resp_ipfs.data); 
                            this.setState({ search_result: this.state.search_result});
                        }                
                    });   
                }
                //console.log("search_result", this.state.search_result[0]);
                this.setState({ isOpen: false }); 
                //return response.data.data.rows[0];
              } else {
                this.dialog_title = "Error";
                this.dialog_content = "NIM `"+nim.toString()+"` tidak ditemukan.";
                this.setState({ isOpen: true }); 
                this.getAllNFT();
                return null
              }
            } catch (error) {
              console.log(error);
            }

        } else {
            this.setState({ isOpen: false });
            this.setState({ search_result: [] });   
            this.getAllNFT();
        }
    };

    async getAllNFT() {
        const address = this.state.sc_account; 
        const chain = EvmChain.create(this.state.network);  
        const response = await Moralis.EvmApi.nft.getContractNFTs({address,chain});
        const resp = response.toJSON();
        // const metadata = resp.result.metadata.toJSON();
        // console.log(metadata);
        const mtdata = [];
        for(var a=0; a < resp.result.length; a++) {
            const meta = resp.result[a].metadata;
            if(meta) {
                mtdata.push(JSON.parse(meta));
                // console.log(meta);
            }
        }
        
        //console.log("mtdata", mtdata);

        this.setState({ 
            data_nft: resp.result,
            metadata: mtdata
        });
    }

    previewImage (event) {
        event.preventDefault();
        const image = document.querySelector('#image');
        const imgPreview = document.querySelector('.img-preview');

        imgPreview.style.display = 'block';

        const oFReader = new FileReader();
        oFReader.readAsDataURL( image.files[0] );
        
        oFReader.onload = function (oFREvent) {
            imgPreview.src = oFREvent.target.result;
        }
    }

    // mengambil nilai ketika form masukan diisi & mengembalikan hash sebagai Id Internal Ijazah secara real-time
    get_value() {
        const _NIM = document.getElementsByName('NIM')[0].value.toString;
        const _studentName = document.getElementsByName('studentName')[0].value;
        // const _studentAddress = document.getElementsByName('studentAddress')[0].value;
        const _major = document.getElementsByName('major')[0].value;
        const _department = document.getElementsByName('department')[0].value;
        const _faculty = document.getElementsByName('faculty')[0].value;
        const _graduationYear = document.getElementsByName('graduationYear')[0].value.toString;

        return document.getElementsByName('studentAddress')[0].value = btoa(_NIM+_studentName+_major+_department+_faculty+_graduationYear);
    }

    detailNFT = async (uid) => {
        this.dialog_title = "Silahkan tunggu";
        this.dialog_content = "Sedang dilakukan pengambilan data onchain...";
        this.setState({ isOpen: true });
    }

    render() {
        
        // console.log("this.state.search_result.length",this.state.search_result.length);

        if (this.state.search_result.length > 0) {
            return (
                <>            
                <div className='container mx-auto'>
                  <Modal
                    show={this.state.isOpen}
                    onHide={this.closeModal}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{this.dialog_title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.dialog_content}</Modal.Body>
                  </Modal>

                    <div className="shadow mb-2 fs-6 p-2 fw-bold bg-body mx-auto rounded bd-highlight border text-center border-secondary">GALLERY NFT</div>

                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Masukan NIM sebagai kata kunci pencarian..."
                          aria-label="Masukan NIM sebagai kata kunci pencarian..."
                          aria-describedby="basic-addon2"
                          defaultValue=""
                          onChange={this.searchNIM.bind(this)}

                        />
                        <Button variant="outline-secondary" type="submit" id="cari">Cari</Button>
                      </InputGroup>

                    <div className="row">
                    {this.state.search_result.length === 0 && 
                        <div className="shadow mb-2 fs-6 p-2 bg-body mx-auto rounded bd-highlight border text-center border-secondary">Pencarian Data NFT tidak ditemukan.</div>
                    }
                    {this.state.search_result.map(token => 
                        <SearchResult 
                            nft={token} 
                        />)
                    }
                    </div>
                </div>            
                </>
            );

        } else {
            return (
                <>            
                <div className='container mx-auto'>
                  <Modal
                    show={this.state.isOpen}
                    onHide={this.closeModal}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{this.dialog_title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.dialog_content}</Modal.Body>
                  </Modal>

                    <div className="shadow mb-2 fs-6 p-2 fw-bold bg-body mx-auto rounded bd-highlight border text-center border-secondary">GALLERY NFT</div>

                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Masukan NIM sebagai kata kunci pencarian..."
                          aria-label="Masukan NIM sebagai kata kunci pencarian..."
                          aria-describedby="basic-addon2"
                          defaultValue=""
                          onChange={this.searchNIM.bind(this)}

                        />
                        <Button variant="outline-secondary" type="submit" id="cari">Cari</Button>
                      </InputGroup>

                    <div className="row">
                        {this.state.metadata.length === 0 && 
                            <div className="shadow mb-2 fs-6 p-2 bg-body mx-auto rounded bd-highlight border text-center border-secondary">Data NFT tidak ditemukan.</div>
                        }
                        {this.state.metadata.map(token => 
                            <GalleryCard 
                                detailNFT={this.detailNFT}
                                key={token.NIM} 
                                nft={token} 
                            />)
                        }

                    </div>
                </div>            
                </>
            );

        }
    }
}


export default GalleryNFT;
