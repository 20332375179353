//src/components/NFTCard.js
import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

//component that takes an nft object and maps it to corresponding elements
const NFTCard = ({handleMinting, key, nft}) => {
    return (
        <>
        <Card style={{ width: '18rem' }}>
          <Card.Img variant="top" className="img-fluid" width="auto" height="100%" src={`https://infura-ipfs.io/ipfs/${nft.hashImage}`} />
          <Card.Body>
            <Card.Title>{parseInt(nft.NIM)}<br/>{nft.studentName.toUpperCase()}</Card.Title>
            <Card.Text>
                <ul>
                    <li><small>{nft.major}</small></li>
                    <li><small>{nft.department}</small></li>
                    <li><small>{nft.faculty}</small></li>
                    <li><small>{parseInt(nft.graduationYear)}</small></li>                    
                </ul>
                <small>{nft.walletAddress}</small>
            </Card.Text>
            <Form onSubmit={(event) => {
                event.preventDefault();
                var id = parseInt(nft.id);
                handleMinting(id);
            }}>
                <Button variant="success" type="submit">Mint</Button>
            </Form>            
          </Card.Body>
        </Card>

        </>
    )
}

export default NFTCard;