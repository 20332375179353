//src/components/NFTCard.js
import React from 'react';
import Card from 'react-bootstrap/Card';

const SearchResult = ({nft}) => {
    return (
        <>
        <Card style={{ width: '18rem' }}>
          <Card.Img variant="top" className="img-fluid" width="auto" height="100%" src={`https://certiproof.infura-ipfs.io/ipfs/${nft.image}`} />
          <Card.Body>
            <Card.Title>{nft.name}</Card.Title>
            <Card.Text>
                <ul>
                {nft.attributes.map((trait, index) => (
                    <li key={index}><small>{trait['trait_type']}: {trait.value}</small></li>
                ))}                    
                </ul>
            </Card.Text>
          </Card.Body>
        </Card>

        </>
    )
}

export default SearchResult;