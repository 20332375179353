import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
// import FormControl from 'react-bootstrap/FormControl';


class Track extends Component {
    /**
    constructor(props) {
        super(props);
    }
    **/
    
    getSet_tract_value1() {        
        let _publisherWalletAddress1get = document.getElementsByName('publisherWalletAddress1get')[0].value;
        return document.getElementById("publisherWalletAddress2set").href = `https://polygonscan.com/address/${_publisherWalletAddress1get}`;
    }

    getSet_tract_value2() {
        let _recordTransactionHash1get = document.getElementsByName('recordTransactionHash1get')[0].value;
        return document.getElementById("recordTransactionHash2set").href = `https://polygonscan.com/tx/${_recordTransactionHash1get}`;
    }

    getSet_tract_value3() {
        let _IPFSHash1get = document.getElementsByName('IPFSHash1get')[0].value;
        return document.getElementById("IPFSHash2set").href = `https://infura-ipfs.io/ipfs/${_IPFSHash1get}`;
    }
    
    render() {
        return (
            <>
                <Container className='my-4'>
                    <Col sm={12} md={8} lg={6} className='mx-auto'>
                        Alamat Smart contract {this.props.contractAddress} <a href={`https://mumbai.polygonscan.com/address/${this.props.contractAddress}`} id='ctxAddress' rel='noreferrer' target='_blank'><Button variant="outline-secondary">Explore</Button></a>
                    </Col>
                </Container>

            </>
        );
    }
}

export default Track;
