//src/components/NFTCard.js
import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

//component that takes an nft object and maps it to corresponding elements
const GalleryCard = ({detailNFT, key, nft}) => {
    return (
        <>
        <Card style={{ width: '18rem' }}>
          <Card.Img variant="top" className="img-fluid" width="auto" height="100%" src={`https://infura-ipfs.io/ipfs/${nft.image}`} />
          <Card.Body>
            <Card.Title>{nft.name}</Card.Title>
            <Card.Text>
                <ul>
                {nft.attributes.map((trait, index) => (
                    <li><small>{trait['trait_type']}: {trait.value}</small></li>
                ))}                    
                </ul>
            </Card.Text>
            <Form onSubmit={(event) => {
                event.preventDefault();
                var id = parseInt(nft.token_id);
                detailNFT(id);
            }}>
                <Button variant="success" type="submit">Detail</Button>
            </Form>            
          </Card.Body>
        </Card>

        </>
    )
}

export default GalleryCard;