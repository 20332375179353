import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/css/bootstrap.min.css";
//import 'bootstrap/dist/css/bootstrap.css';
import App from './components/App.jsx';
// import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';

/**
import webpack from 'webpack';
import webpackConfig from './webpack.config.js';


var config = await webpackConfig("dev");
var compiler = webpack(config);
compiler.watch()
**/

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// serviceWorker.unregister();
reportWebVitals();