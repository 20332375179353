import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Identicon from 'identicon.js';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navStatus: null,
        };
    }    
    
    navigate = () => { 
        return useNavigate();  
    }       
    
    // const navigate = useNavigate();                         

    toGallery = () => { 
        const path = `/galery`; 
        this.navigate(path);
    }

    toWhitelist = () => { 
        const path = `/whitelist`; 
        this.navigate(path);
    }
  
    toGalleryNft = () => { 
        const path = `/gallerynft`; 
        this.navigate(path);  
    }

    render() {
        return (
            <Navbar collapseOnSelect expand="md" bg="dark" variant="dark" sticky="top">
                <Container>
                    <Navbar.Brand to="/"><Button variant="success">CertiProof</Button></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link><Link to='/' className={this.state.navStatus === 'home' ? 'active' : ''} onClick={() => this.setState({navStatus: "home"})}>Home</Link></Nav.Link>
                            <Nav.Link><Link to='/uploads' className={this.state.navStatus === 'uploads' ? 'active' : ''} onClick={() => this.setState({navStatus: "uploads"})}>Upload</Link></Nav.Link>
                            <NavDropdown title="Data" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/galery">Data Upload (on-chain)</NavDropdown.Item>
                                <NavDropdown.Item href="/whitelist">Data Whitelist (mint NFT)</NavDropdown.Item>
                                <NavDropdown.Item href="/gallerynft">Gallery NFT</NavDropdown.Item>
                            </NavDropdown>          
                            <Nav.Link><Link to='/about' className={this.state.navStatus === 'about' ? 'active' : ''} onClick={() => this.setState({navStatus: "about"})}>About</Link></Nav.Link>
                            <Nav.Link><Link to='/track' className={this.state.navStatus === 'track' ? 'active' : ''} onClick={() => this.setState({navStatus: "track"})}>Track</Link></Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link to='/' className='mr-2'>{this.props.account}</Nav.Link>
                            { this.props.account
                            ? <img
                                width='30'
                                height='30'
                                alt='icon'
                                src={`data:image/png;base64,${new Identicon(this.props.account, 30).toString()}`}
                            />
                            : <Nav.Link to='/'>[not connected]</Nav.Link>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default NavBar;
