import React, { Component } from 'react';

import { 
    BrowserRouter as Router,
    Routes, 
    Route
} from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from './NavBar.jsx';
import Welcome from './Welcome.jsx';
// import Home from './Home.jsx';
import Track from './Track.jsx';
import About from './About.jsx';
import WhiteList from './WhiteList.jsx';
import GalleryNFT from './GalleryNFT.jsx';
import Galery from './Galery.jsx';
import Upload from './Upload.jsx';
import CertiProof from '../abis/CertiProof.json';
import './App.css';
import { Web3 } from 'web3';
// import { isAddress } from 'web3-validator';

// import { create } from 'ipfs-http-client';
// import { Buffer } from 'buffer';
import Favicon from "react-favicon";
// import jsonData from './setting.json';


/**
const projectId = jsonData.INFURA_API_KEY;
const projectSecret = jsonData.INFURA_API_KEY_SECRET;
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const ipfs = create({ 
    host: 'ipfs.infura.io', 
    port: '5001', 
    protocol: 'https',
    headers: {
        authorization: auth,
    },
})
**/

class App extends Component {

    async componentDidMount(){
        await this.loadWeb3();
        await this.loadBlockchainData();
    }

    async loadWeb3(){
        if(window.ethereum){
            window.web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
        } else if(window.web3){
            window.web3 = new Web3(window.web3.currentProvider);
        } else {
            window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
    }

    async loadBlockchainData() {
        const web3 = window.web3;
        // Load account
        const accounts = await web3.eth.getAccounts();
        this.setState({ account: accounts[0] });

        // Network ID
        const networkId = await web3.eth.net.getId();
        const networkData = CertiProof.networks[parseInt(networkId)];
        if(networkData){
            const sc = new web3.eth.Contract(CertiProof.abi, networkData.address);
            const recordsCount = await sc.methods.recordsCount().call();

            //console.log('universityDiplomaRecords '+universityDiplomaRecords);
            //console.log('recordsCount  '+recordsCount);

            // load Diploma Records
            for(let i = 1; i <= parseInt(recordsCount); ++i){
                const diplomaRecord = await sc.methods.diplomaRecords(i).call();
                this.setState({
                    diplomaRecords: [...this.state.diplomaRecords, diplomaRecord],
                })
                //console.log('this.state.diplomaRecords ', this.state.diplomaRecords);
                //console.log('diplomaRecord ', diplomaRecord);
            }

            this.setState({ 
                contractAddress: networkData.address,
                sc, 
                recordsCount, 
                loading: false 
            });
        }else{
            window.alert('University Diploma Records contract not deployed to detected network!');
        }
    }

    
    constructor(props) {
        super(props);
        this.state = {
            account: '',
            contractAddress: null,
            universityDiplomaRecords: null,
            diplomaRecords: [],
            loading: true
        };
    }

    render() {
        return (
            <>
            <div className="app">            
              <Router>  
                <Favicon url="http://oflisback.github.io/react-favicon/img/github.ico" />   
                <NavBar account={this.state.account} />                
                <Routes>
                    <Route path='/uploads' element= {
                        <Upload 
                        diplomaRecords={this.state.diplomaRecords}
                        wallet_admin={this.state.account}                        
                        sc={this.state.universityDiplomaRecords}
                    />} />
                    <Route path='/gallerynft' element={
                        <GalleryNFT
                        wallet_admin={this.state.account}

                    />} />             
                    <Route path='/galery' element={
                        <Galery 
                        wallet_admin={this.state.account}

                    />} />                           
                    <Route path='/about' element={<About />} />
                    <Route path={"/whitelist"} element={<WhiteList />} />                    
                    <Route index element={<Welcome />} exact />
                    <Route path='/track' element={
                        <Track 
                            contractAddress={this.state.contractAddress} 
                        />} 
                    />
                </Routes>
              </ Router>  
                {
                    this.state.loading
                    ? 
                    <div id="loader" className="text-center mt-5"><p>Loading...</p></div>
                    : ""
                }

            </div>
            </>
        );
    }
}

export default App;
