import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import "bootstrap/dist/css/bootstrap.min.css";
import Col from 'react-bootstrap/Col';
// import Tab from 'react-bootstrap/Tab';
// import Nav from 'react-bootstrap/Nav';

class About extends Component {
    render() {
        return (
            <>
                <Container className='my-4'>
                    <Col sm={12} md={8} lg={8} className='mx-auto'>
                        <h1 className='h3 mb-4'>DApp Simulasi Sistem Pencatatan Ijazah</h1>

                        <p className='justify-text'>Aplikasi terdesentralisasi (DApp) ini dibangun menggunakan kombinasi teknologi <i>Blockchain</i> dengan <i>InterPlanetary File System</i> (IPFS), yang dirancang untuk mensimulasikan penerbitan ijazah universitas. Sekaligus bertujuan untuk memperoleh informasi terkait performansi atau kualitas jaringan sistem dengan melalui teknik pengujian <i>Quality of Service</i> (QoS), serta mengetahui berapa kisaran biaya yang diperlukan untuk pengunggahan setiap ijazahnya.</p>
                        <p className='justify-text'>Ijazah merupakan sebuah dokumen atau sertifikat yang diberikan kepada seseorang yang telah menyelesaikan jenjang pendidikan formal. Ijazah sering dijadikan tolak ukur seseorang untuk mendapatkan pekerjaan serta sebagai identitas di mata lingkungan sosial. Keaslian ijazah merupakan hal yang sangat penting, dikarenakan ijazah dapat digunakan sebagai salah satu bukti standar kelulusan seseorang dalam studi atau pendidikannya. Pada umumnya untuk menyatakan integritas, keabsahan atau keaslian ijazah diperlukan legalisasi dengan cara memverifikasi atau menghubungi, kepada lembaga yang bersangkutan atau mengeluarkan ijazah tersebut. Proses ini sangat memerlukan waktu yang lama dan kurang efesien. Tidak sedikit orang yang menganggap ijazah merupakan hal yang bermakna atau esensial. Sehingga kerap kali terjadi maraknya pemalsuan ijazah, meskipun pemalsuan ijazah tersebut dapat digolongkan sebagai tindak kriminal karena telah melanggar norma hukum dan telah melanggar Hak Atas Kekayaan Intektual (HAKI) seseorang. Untuk mengantisipasi pemalsuan tersebut, di Indonesia saat ini telah terdapat sistem Penomoran Ijazah Nasional (PIN) dan sekaligus Sistem Verifikasi Ijazah Online (SIVIL). Namun penyimpanan database ijazah tersebut masih bersifat terpusat yang masih memungkinkan terjadinya peretasan ilegal. Atas dasar tersebut, penelitian ini bertujuan untuk memberikan solusi sistem pencatatan ijazah yang lebih aman dan terpercaya, yaitu dengan menggunakan sistem Blockchain. Namun saat ini Blockchain tengah menghadapi isu Trilemma Blockchain, oleh karena itu penelitian ini akan menggunakan layer-2 Ethereum Blockchain, yaitu Polygon Sidechain pada jaringan EIP- 1559 sebagai solusi isu skalabilitas jaringan Ethereum. Metode pengujian yang akan digunakannya ialah Quality of Service. Pengujian lainnya juga terdapat perbandingan biaya yang dibutuhkan setiap kali pengunggahan ijazah jaringan Blockchain tersebut.</p>
                        <div className='justify-text'>Berikut ringkasan diagram alur (<i>flowchart</i>), Usecase dan Class Diagram beserta sequence diagram yang secara ringkas menjelaskan bagaiman alur proses, metadata, dan input-output serta interaksi aplikasi ini bekerja dengan <i>blockchain</i>.
                            <br /><img src="/flowchart.jpg" alt="flowchart" className="img-thumbnail" /><div align="center">Gambar 1. Diagram Alur (<i>flowchart</i>)</div>
                            <br /><img src="/usecase.jpg" alt="Usecase" className="img-thumbnail" /><div align="center">Gambar 2. Use Case dan Class Diagram</div>
                            <br /><img src="/diagram-sequence.jpg" alt="sequence diagram" className="img-thumbnail" /><div align="center">Gambar 3. <i>Sequence Diagram</i></div>
                        </div>
                        <p className="justify-text">Untuk menggunakan aplikasi ini dengan sempurna harap instal terlebih dahulu dompet Metamask sebagai extention ataupun addon pada browser anda. Panduan konfigurasi dan cara penggunaan dompet MetaMask sebagai berikut;</p>
                    </Col>

                </Container>
            </>
        );
    }
}

export default About;
